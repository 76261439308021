<template>
  <div class="container col-start-center">
    <div :class="['nav', 'row-between-center', isDark ? 'dark' : '']">
      <div class="row-start-center">
        <img src="https://img.chaolu.com.cn/MINI/coach-feelings/logo.png" alt="" class="logo">
        <div>
          <p class="p1 f28 fw6">超鹿运动App</p>
          <p class="f22 grey">按次付费的健身房</p>
        </div>
      </div>
      <div class="btn f24 fw6 row-center-center">打开App</div>
    </div>

    <div v-if="!isDark" class="poster">
      <swiper class="swiper" :options="swipeOption" ref="swiper">
        <swiper-slide v-for="(item, index) in 2">
          <img src="https://img.chaolu.com.cn/MINI/coach-feelings/share.png" alt="">
        </swiper-slide>
      </swiper>
      <div class="f24 fw6 count row-center-center">{{ swiperIndex }}/5</div>
    </div>

    <div v-if="isDark" class="main dark flex-auto col-start-center">
      <video @click="pause" ref="videoPlayer" class="video-js vjs-big-play-centered" playsinline="true"
      webkit-playsinline="true" x-webkit-airplay="true" x5-video-player-type="h5-page" x5-video-player-fullscreen="false"
      x5-video-orientation="portraint" style="object-fit: cover; width: 100%; height: 100%;"
      src="http://img.chaolu.com.cn/activity/summary2020/vv1.mp4" />
      <img v-show="!isPlay" @click="play" class="play-btn" src="http://img.chaolu.com.cn/MINI/icon/dongtai-play.png" alt="">
      <div class="inner">
        <div class="coach row-start-center">
          <img src="" alt="" class="avator">
          <p class="name f28">教练名称</p>
        </div>
        <p class="desc ellipsis-2 f30">
          今晚的蹦床音乐特别棒，在释放中燃脂，今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂，今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂。
          今晚的蹦床音乐特别棒，在释放中燃脂，今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂，今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂。
          今晚的蹦床音乐特别棒，在释放中燃脂，今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂，今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂。
          今晚的蹦床音乐特别棒，在释放中燃脂，今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂，今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂。
          今晚的蹦床音乐特别棒，在释放中燃脂，今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂，今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂。
          今晚的蹦床音乐特别棒，在释放中燃脂，今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂，今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂。
          今晚的蹦床音乐特别棒，在释放中燃脂，今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂，今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂。
          今晚的蹦床音乐特别棒，在释放中燃脂，今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂，今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂。
          今晚的蹦床音乐特别棒，在释放中燃脂，今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂，今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂。
          今晚的蹦床音乐特别棒，在释放中燃脂，今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂，今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂。
          今晚的蹦床音乐特别棒，在释放中燃脂，今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂，今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂。
        </p>

        <div class="course dark flex">
          <div class="item small row-start-center">
            <img class="flex-none" src="https://chaolu.oss-cn-hangzhou.aliyuncs.com/MINI/icon/dongtai-class-icon.png" alt="">
            <p class="f24 ellipsis">燃脂塑形小班课燃脂塑形塑形…</p>
          </div>
          <div class="item small row-start-center">
            <img class="flex-none" src="https://chaolu.oss-cn-hangzhou.aliyuncs.com/MINI/icon/dongtai-loc-icon.png" alt="">
            <p class="f24 ellipsis">燃脂塑形小班课燃脂塑形塑形…</p>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="main flex-auto">
      <div class="coach row-start-center">
        <img src="" alt="" class="avator">
        <p class="name f28">教练名称</p>
      </div>
      <p class="desc f30">
        今晚的蹦床音乐特别棒，在释放中燃脂，今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂，今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂。
        今晚的蹦床音乐特别棒，在释放中燃脂，今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂，今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂。
        今晚的蹦床音乐特别棒，在释放中燃脂，今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂，今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂。
        今晚的蹦床音乐特别棒，在释放中燃脂，今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂，今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂。
        今晚的蹦床音乐特别棒，在释放中燃脂，今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂，今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂。
        今晚的蹦床音乐特别棒，在释放中燃脂，今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂，今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂。
        今晚的蹦床音乐特别棒，在释放中燃脂，今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂，今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂。
        今晚的蹦床音乐特别棒，在释放中燃脂，今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂，今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂。
        今晚的蹦床音乐特别棒，在释放中燃脂，今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂，今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂。
        今晚的蹦床音乐特别棒，在释放中燃脂，今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂，今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂。
        今晚的蹦床音乐特别棒，在释放中燃脂，今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂，今晚的蹦床音乐特别棒，在释放中燃脂。今晚的蹦床音乐特别棒，在释放中燃脂。
      </p>
      <p class="time f22 grey">2023-03-10 14:32</p>
    </div>



    <div v-if="!isDark" class="course flex flex-none">
      <div class="item row-start-center">
        <img class="flex-none" src="" alt="">
        <div class="flex-auto">
          <p class="f20 ellipsis">福州市中庚青年广场店</p>
          <p class="f20 ellipsis">鼓楼区东街83号中庚青年广场4楼</p>
        </div>
      </div>
      <div class="item row-start-center">
        <img class="flex-none" src="" alt="">
        <div class="flex-auto">
          <p class="f20 ellipsis">福州市中庚青年广场店</p>
          <p class="f20 ellipsis">鼓楼区东街83号中庚青年广场4楼鼓楼区东街83号中庚青年广场4楼</p>
        </div>
      </div>
    </div>

    <div :class="['footer', 'row-end-center', isDark ? 'dark' : '']">
      <div class="action row-start-center f28 fw6">
        <img :src="`https://chaolu.oss-cn-hangzhou.aliyuncs.com/MINI/icon/zan-${isDark ? 'bai' : 'hei'}.png`" alt="">
        <span>23</span>
      </div>
      <div class="action row-start-center f28 fw6">
        <img :src="`https://chaolu.oss-cn-hangzhou.aliyuncs.com/MINI/icon/share-${isDark ? 'bai' : 'hei'}.png`" alt="">
        <span>23</span>
      </div>
    </div>
  </div>
</template>

<script>
import userMixin from '@/mixin/userMixin'
import { newAppBack, initBack } from '@/lib/appMethod'
import { getParam } from '@/lib/utils'
import { wxShare } from '@/lib/wx-sdk'
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'

export default {
  mixins: [userMixin],
  components: {
    swiper, swiperSlide
  },
  data() {
    return {
      swipeOption: {
        on: {
          slideChangeTransitionStart: () => {
            if (this.swiper) {
              this.swiperIndex = this.swiper.realIndex + 1
            }
          },
        },
      },
      isDark: false,
      isPlay: false,
      swiperIndex: 1,
    }
  },
  computed: {
    swiper() {
      return this.$refs.swiper.swiper
    },
  },
  async mounted() {
    await this.$getAllInfo()
    initBack()
    // this.params = getParam()
    // Object.keys(this.params).forEach((key) => {
    //   this.params[key] = decodeURIComponent(this.params[key])
    // })

    // this.getDetail()
    wxShare({ title: '教练', desc: 'hhh' })
  },
  methods: {
    appBack: newAppBack,
    getDetail() {
      this.$axios.post(this.baseURLApp + '/userDistribute/bindUser', {
        id: ''
      }).then((res) => {
        console.log(res)
      })
    },
    play() {
      this.isPlay = true
      this.$refs.videoPlayer.play()
    },
    pause() {
      this.isPlay = false
      this.$refs.videoPlayer.pause()
    }
  },
}
</script>
<style lang="less" scoped>
.container {
  height: 100vh;
  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }

  .nav {
    width: 100%;
    position: sticky;
    top: 0;
    left: 0;
    background-color: #fff;
    padding: 26px 32px;
    z-index: 3;

    &.dark {
      background-color: #000000;

      .p1 {
        color: #fff;
      }
    }
    .p1 {
      margin: 0 0 4px;
    }
    .logo {
      width: 68px;
      margin: 0 20px 0 0;
    }

    .btn {
      padding: 0 26px;
      height: 56px;
      background: #FFDE00;
      border-radius: 4px 4px 4px 4px;
    }
  }

  .poster {
    width: 100%;
    position: relative;

    .swiper {
      width: 100%;
      height: 244px;

      .swiper-slide {
        height: 244px;
      }

      img {
        width: 750px;
        height: 100%;
        object-fit: contain;
      }
    }

    .count {
      min-width: 70px;
      padding: 0 16px;
      height: 48px;
      background: rgba(0, 0, 0, 0.4);
      border-radius: 100px 100px 100px 100px;
      color: #fff;
      position: absolute;
      top: 28px;
      right: 28px;
      z-index: 2;
    }
  }


  .main {
    width: 100%;
    padding: 28px 32px;
    margin: 52px 0 0;
    position: relative;
    &.dark {
      color: #fff;
      padding: 0;
      margin: 0;
      overflow: hidden;
      .inner {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 0 28px;
        background: linear-gradient(0deg, rgba(0,0,0,.7) 0%, rgba(0,0,0,0) 100%);
      }
      .play-btn {
        width: 120px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 999;
      }
      .desc {
        max-height: 98px;
      }
    }

    .coach {
      margin: 0 0 24px;

      .avator {
        width: 64px;
        border-radius: 50%;
        margin: 0 16px 0 0;
      }
    }
    .desc {
      margin: 0 0 28px;
      line-height: 48px;
    }
  }

  .course {
    width: 100%;
    margin: 48px 0;
    padding: 0 24px 0 0;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    &.dark {
      margin: 24px 0 48px;
    }
    .item {
      width: 424px;
      height: 88px;
      padding: 0 22px 0 0;
      border-radius: 4px 4px 4px 4px;
      border: 2px solid #EBEBEB;
      margin: 0 0 0 24px;
      img {
        width: 88px;
        height: 88px;
        margin: 0 22px 0 0;
      }
      & > div {
        overflow: hidden;
      }
      &.small {
        width: 406px;
        height: 52px;
        padding: 0 8px;
        background: rgba(255,255,255,0.15);
        margin: 0 32px 0 0;
        border: none;
        img {
          width: 36px;
          height: 36px;
          margin: 0 10px 0 0;
        }
      }
    }
  }

  .footer {
    width: 100%;
    position: sticky;
    bottom: 0;
    left: 0;
    background-color: #fff;
    padding: 28px 32px calc(28px + env(safe-area-inset-bottom));

    &.dark {
      background-color: #000000;
      color: #fff;
    }

    .action {
      margin: 0 0 0 48px;

      img {
        width: 48px;
        margin: 0 10px 0 0;
      }
    }
  }

  .grey {
    color: #999;
  }
}
</style>
